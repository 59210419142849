import React from 'react';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Datagrid,
    DeleteButton,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import DetailRecordEditingButton from "../../components/DetailRecordEditingButton";
import TeacherClazzFieldsDialog from "../TeacherClazz/TeacherClazzFieldsDialog";
import {ClazzTypeInput} from "../../components/ClazzType";

const styles = {
    container: {
        width: 400,
    }
}

const ClazzFields = ({classes, ...props}) => {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <TextInput style={styles.container} source="name" validate={required()} {...props} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="homeroomTeacher" reference="employeeEnrollments" allowEmpty {...props} >
                    <AutocompleteInput optionText="displayName2"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="calendarIds"
                                     reference="calendarBatches" {...props} allowEmpty>
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()}
                                allowEmpty {...props} >
                    <SelectInput optionText="name"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="parentId" reference="grades"
                                validate={required()} allowEmpty {...props} >
                    <SelectInput style={styles.container} optionText="name"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ClazzTypeInput source="clazzType" {...props} validate={required()}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="note" {...props} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="viewerIds" reference="customers" {...props} >
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="checkerIds" reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>

            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="makerIds"
                                     reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="editStudentInfoIds"
                                     reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="authorizedStudentInfoIds"
                                     reference="customers" {...props}>
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="authorizedFullDayIds" reference="customers" {...props} >
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="contactViewerIds" reference="customers" {...props} >
                    <AutocompleteArrayInput optionText={'displayName'}/>
                </ReferenceArrayInput>
            </Grid>

            <Grid item xs={12} sm={8}>
                <DetailRecordEditingButton
                    isCreateButton detailResource="teacherClazzes"
                    record={{unitId: props.record.id}}
                    title="Thêm giáo viên bộ môn"
                    customLabel="Thêm giáo viên bộ môn"
                    variant={true}
                >
                    <TeacherClazzFieldsDialog/>
                </DetailRecordEditingButton>
            </Grid>
            {props.record.teacherClazzes && props.record.teacherClazzes.length > 0 &&
            <Grid item xs={12} sm={12}>
                <ReferenceManyField
                    reference="teacherClazzes"
                    target="unitId"
                    basePath={props.basePath}
                    record={props.record}
                >
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="subject"/>
                        <ReferenceField source="customerId" reference="customers" allowEmpty>
                            <TextField source="name"/>
                        </ReferenceField>
                        <DetailRecordEditingButton
                            detailResource="deliveryDocumentItems"
                            title="Cập nhật giáo viên bộ môn"
                        >
                            <TeacherClazzFieldsDialog/>
                        </DetailRecordEditingButton>
                        <DeleteButton
                            basePath={props.basePath}
                            record={props.record}
                            resource={'teacherClazzes'}
                            redirect={false}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Grid>
            }

        </React.Fragment>
    )
}
export default ClazzFields;
