import React from 'react';
import {AppBar, MenuItemLink, translate} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import {withStyles} from '@material-ui/core/styles';
import UserMenu from "./UserMenu";
import ChangePasswordDiaglog from "../pages/User/ChangePasswordDiaglog";

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomUserMenu = translate(({ translate, onClick, ...props }) => {

  return (
      <UserMenu {...props} onClick={onClick}>
        <MenuItemLink
            to={"/"}
            primaryText={translate('resources.users.changePassword')}
            leftIcon={<SettingsIcon />}
        />
      </UserMenu>
  )
});

const CustomAppBar = ({ classes, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
      <AppBar {...props} userMenu={<CustomUserMenu onClick={handleClickOpen}/>}>
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <span className={classes.spacer} />
        <div>
          <ChangePasswordDiaglog close={handleCloseDialog} open={open} />
        </div>
      </AppBar>
  )
};

export default withStyles(styles)(CustomAppBar);
