import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {Title, ReferenceInput, AutocompleteInput, required} from 'react-admin';
import {
    downloadFile,
    NotifyForm,
    schoolYears,
    showErrorNotify, TreeCheckboxInput
} from '../../components';
import env from '../../env';

class BcDanhSachHS extends React.Component {
    state = {
        changeSchoolYear: false,
        unitIds: [],
        schoolYearId: schoolYears[0].id,
    };

    getReportUrl = (type, fullDay, allStudent) => {
        downloadFile(`${env.baseURL}/api/enrollment/report/danhSachHocSinh?` +
            `fullDayOnly=` + fullDay +
            `&allStudent=` + allStudent +
            `&schoolYearId=` +  this.state.schoolYearId +
            `&unitIds=` + this.state.unitIds, 'danh_sach_HS.xlsx')
    }


    billBatchTemplateAllClazzes = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl('XLS', false, true)
    }

    fullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }

        this.getReportUrl('XLS', true, false)
    }

    notFullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl('XLS', false, false)
    }

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        if (this.state.changeSchoolYear) {
            this.setState({
                unitIds: [],
                changeSchoolYear: false
            })
        } else {
            this.setState({
                unitIds: values.unitIds
            })
        }

        return errors;
    }

    onSchoolYearChanged(e, value) {
        this.setState({
            schoolYearId: value,
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.bcDanhSachHS.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                allClazzes={this.billBatchTemplateAllClazzes}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="schoolYearId"
                                                reference="schoolYears"
                                                label="resources.schoolYears.name"
                                                perPage={50}
                                                validate={required()}
                                                onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                                {...this.props} >
                                    <AutocompleteInput source="name" />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{clazzWithParents: true, schoolYear: this.state.schoolYearId}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}
                                                                     schoolYear={this.state.schoolYearId} />
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default BcDanhSachHS

