import React from 'react';
import { Login } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = ({
  avatar: {
    background: 'url(\'https://api.vinaid.vn/files/logo.png\')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: '5% 30%',
    height: 128,
    justifyContent: 'center',
    display: 'flex'
  },
  icon: { display: 'none' },
});

const CustomLoginPage = props => (
    <Login
      backgroundImage='https://api.vinaid.vn/files/bg.jpg'
      {...props}
    />
);

export default withStyles(styles)(CustomLoginPage);
