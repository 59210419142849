import PollIcon from '@material-ui/icons/ViewList';

import PollList from './PollList';
import PollCreateOrUpdate from './PollCreateOrUpdate';

export default {
  list: PollList,
  create: PollCreateOrUpdate,
  edit: PollCreateOrUpdate,
  icon: PollIcon,
};
