import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import moment from 'moment'
import env from '../../env';
import TreeCheckboxInputWithDialog from "./TreeCheckboxInputWithDialog";
import axiosInstance from "../../api/axios";
import {downloadFilePost} from "../../components/FileDownload";

class BillBatchTemplate extends React.Component {
    state = {
        loading: false,
        inputDayAutomatic: false,
        changeSchoolYear: false,
        unitIds: [],
        schoolYearId: schoolYears[0].id,
        billMonth: moment().format('YYYY-MM-DD'),
        thangTinhNgayNghi: moment().format('YYYY-MM-DD'),
    };

    componentDidMount() {
        axiosInstance.get('/api/billBatches/needInputDay').then((res) => {
            this.setState({
                inputDayAutomatic: res.data
            })
        })
    }

    getReportUrl = (fullDay, allStudent) => {
        this.setState({
            loading: true
        })
        const data = {
            fullDayOnly: fullDay,
            allStudent: allStudent,
            schoolYearId: this.state.schoolYearId,
            billMonth: this.state.billMonth,
            thangTinhNgayNghi: this.state.thangTinhNgayNghi,
            unitIdAndDays: this.state.unitIds
        }
        downloadFilePost(`${env.baseURL}/reports/billBatchTemplate`, 'ke_hoach_thu_phi.xlsx', data)
            .then(() => {
                this.setState({
                    loading: false
                })
            }).catch(() => {
            this.setState({
                loading: false
            })
        })
    }

    billBatchTemplateAllClazzes = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, true)
    }

    fullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }

        this.getReportUrl(true, false)
    }

    notFullDayOnly = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, false)
    }

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        if (this.state.changeSchoolYear) {
            this.setState({
                unitIds: [],
                changeSchoolYear: false,
                billMonth: values.billMonth,
                thangTinhNgayNghi: values.thangTinhNgayNghi
            })
        } else {
            this.setState({
                unitIds: values.unitIds,
                billMonth: values.billMonth,
                thangTinhNgayNghi: values.thangTinhNgayNghi
            })
        }

        return errors;
    }

    onSchoolYearChanged(e, value) {
        this.setState({
            schoolYearId: value,
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.billBatchTemplate.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                allClazzes={this.billBatchTemplateAllClazzes}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                                isLoading={this.state.loading}
                    >
                        <Grid container spacing={8}>
                            {this.state.inputDayAutomatic &&
                            <Grid item xs={12} sm={6}>
                                <DateInput style={{width: '25%'}} label="resources.billBatchTemplate.thangTinhNgayNghi"
                                           source="thangTinhNgayNghi" validate={required()} {...this.props} />
                            </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                                <DateInput style={{width: '25%'}} label="resources.billBatchTemplate.billMonth"
                                           source="billMonth" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '450px', overflow: 'auto'}}>
                                    {this.state.inputDayAutomatic ?
                                        <ReferenceInput source="unitIds"
                                                        reference="units"
                                                        perPage={999}
                                                        filter={{
                                                            clazzWithParents: true,
                                                            schoolYear: this.state.schoolYearId
                                                        }}
                                                        {...this.props} >
                                            <TreeCheckboxInput optionValue="id" optionText="name"
                                                               expandTopLevel={true}/>
                                        </ReferenceInput>
                                        :
                                        <ReferenceInput source="unitIds"
                                                        reference="units"
                                                        perPage={999}
                                                        filter={{
                                                            clazzWithParents: true,
                                                            schoolYear: this.state.schoolYearId
                                                        }}
                                                        {...this.props} >
                                            <TreeCheckboxInputWithDialog optionValue="id" optionText="name"
                                                                         expandTopLevel={true}
                                                                         schoolYear={this.state.schoolYearId}/>
                                        </ReferenceInput>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default BillBatchTemplate

