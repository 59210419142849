import React from 'react';
import {AutocompleteInput, Create, ReferenceInput, SimpleForm} from "react-admin";
import {CustomToolbar, Grid, TreeCheckboxInput} from "../../components";

const UploadPhotoPermissionCreate = (props) => {

    return (
        <Create {...props}>
            <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
                <Grid container spacing={8}>
                    <React.Fragment>
                        <Grid item xs={12} sm={6} >
                            <label>Tài khoản *</label>
                            <ReferenceInput source="userId" reference="helpDeskUsers" perPage={20}>
                                <AutocompleteInput
                                    optionText={({userName, fullName}) => `${userName} (${fullName || ''})`}/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <label>Danh sách lớp *</label>
                            <ReferenceInput source="unitIds"
                                            reference="units"
                                            perPage={999}
                                            filter={{all: true}}
                                            {...props}>
                                <TreeCheckboxInput optionValue="id" optionText="name" />
                            </ReferenceInput>
                        </Grid>

                    </React.Fragment>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default UploadPhotoPermissionCreate;
