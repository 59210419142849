import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, required, TextInput} from 'react-admin';

const KiotConfigFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} inputProps={{maxLength: 100}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="retailerId" validate={required()} inputProps={{maxLength: 40}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="branchId" validate={required()} inputProps={{maxLength: 15}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="saleChannelId" validate={required()} inputProps={{maxLength: 255}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="clientId" validate={required()} inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="clientSecret" validate={required()} inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="seller"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="discountInvoice" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="categoryId" inputProps={{maxLength: 255}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="priceBookId" inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="sellerChannel" inputProps={{maxLength: 50}} {...props} />
        </Grid>
    </React.Fragment>
)

export default KiotConfigFields
