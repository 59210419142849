import React, { Component } from 'react';
import get from 'lodash/get';

import CheckboxTree from 'react-checkbox-tree';

export class TreeCheckboxInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [-9999],
    }
  }

  handleChange = (selectedValues) => {
    this.props.input.onChange(selectedValues);
    if (this.props.onChecked) {
      this.props.onChecked(selectedValues);
    }
  }

  dedupChoices = (choices) => {
    const { 
      optionValue = "id", 
    } = this.props;

    // De-duplicate items
    for (let i=0; i<choices.length; ++i) {
      for (let j=i+1; j<choices.length; ++j) {
        if (get(choices[j], optionValue) === get(choices[i], optionValue)) {
          choices.splice(i--, 1);
        }
      }
    }
  }

  findByParentId = (choices, parentId) => {
    const nodes = choices.filter(choice => choice.parentId === parentId )

    if (nodes.length > 0) {
      nodes.map(node => {
        node.children = this.findByParentId(choices, node.id)
        return false
      })
    }
    const nodeParents = choices.filter(choice => choice.parentId === undefined )
    for (let i = 0; i < nodeParents.length; i++) {
      nodes.push(nodeParents[i])
    }
    return nodes;
  }

  /**
   * CheckboxTree uses (value, label) tube, so we need convert (id, name) ==> (value, label)
   */
  toTreeCheckboxNode = (choice, optionValue, optionText) => {
    if (choice.children === undefined || choice.children.length === 0) {
      return {
        value: get(choice, optionValue),
        label: get(choice, optionText),
      }
    }
    return {
      value: get(choice, optionValue),
      label: get(choice, optionText),
      children: choice.children.map(child => {
        return this.toTreeCheckboxNode(child, optionValue, optionText)
      }),
    }  
  }

  render() {
    const {
      optionValue = "id", 
      optionText = "name",
    } = this.props;

    this.dedupChoices(this.props.choices);

    const choices = this.findByParentId(this.props.choices, null)
    const nodes = [{
      label: 'Chọn tất cả',
      value: -9999,
      children: choices.map(choice => this.toTreeCheckboxNode(choice, optionValue, optionText))
    }]

    const topLevel = nodes[0].children.map(node => node.value)
    const expanded = this.props.expandTopLevel ? this.state.expanded.concat(topLevel) : this.state.expanded

    return (
      <CheckboxTree
        nodes={nodes}
        checked={this.props.input.value || [] }
        onCheck={this.handleChange}

        expanded={expanded}
        onExpand={expanded => this.setState({ expanded }) }

        showNodeIcon={false}
    />
    )
  }
}
