import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {BooleanInput, DateInput, LongTextInput, ReferenceInput, required, Title} from 'react-admin';
import {NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";
import {downloadFilePost} from "../../components/FileDownload";

class UnpaidBillNotify extends React.Component {
    state = {
        isLoading: false,
        displayDept: true,
        billMonth: moment().format('YYYY-MM-DD'),
        params: {
            unitIds: [],
            note: '',
        }
    };

    notifyAllStudentsUrl = (type, fullDayOnly, allStudent) => {
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        return `${env.baseURL}/reports/unpaidBillNotify?` +
            `&note=` + this.state.params.note +
            `&fullDayOnly=` + fullDayOnly +
            `&allStudent=` + allStudent +
            `&displayDept=` + this.state.params.displayDept +
            `&billMonth=` + this.state.params.billMonth +
            `&pUnitIds=` + this.state.params.unitIds;
    };

    getReportUrl = (fullDay, allStudent) => {
        this.setState({isLoading: true})
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        const data = {
            fullDayOnly: fullDay,
            allStudent: allStudent,
            displayDept: this.state.params.displayDept,
            note: this.state.params.note,
            billMonthStr: this.state.params.billMonth,
            unitIds: this.state.params.unitIds
        }
        downloadFilePost(`${env.baseURL}/reports/unpaidBillNotify`, 'thong_bao_hoc_phi.xlsx', data)
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }
    notifyAllStudents = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, true)
    };

    fullDayOnly = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(true, false)
    }

    notFullDayOnly = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.getReportUrl(false, false)
    }

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.unpaidBillNotify.name"/>
                <CardContent>
                    <NotifyForm validate={this.validate}
                                allClazzes={this.notifyAllStudents}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                                params={this.state}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.month" source="billMonth"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput label="resources.unpaidBillNotify.displayDept"
                                              source="displayDept" {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12} sm={6}>
                                    <LongTextInput label="resources.unpaidBillNotify.note"
                                                   rows={5}
                                                   multiline
                                                   source="note" {...this.props} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default UnpaidBillNotify

