import React, {useEffect, useState} from "react"
import utils from "../../utils/utils";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import {BooleanInput, CheckboxGroupInput, showNotification, SimpleForm} from "react-admin";
import SimpleToolbar from "../../components/SimpleToolbar";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

const FaceCreate = () => {
    const [customers, setCustomers] = useState([])
    const [originalCustomers, setOriginalCustomers] = useState([])
    const [selected, setSelected] = useState([])
    const [registeredCustomers, setRegisteredCustomers] = useState([])
    const [devices, setDevices] = useState([])
    const [selectedDeviceNos, setSelectedDeviceNos] = useState([])
    useEffect(() => {
        axiosInstance.get('/api/enrollmentForFaceId').then((res) => {
            if(res.status === 200) {
                const content = res.data.content || []
                setOriginalCustomers(content)
                setCustomers(content)
            }
        })
        axiosInstance.get('/api/face?size=9999').then(res => {
            if (res.status === 200) {
                const cusIds = res.data.content.map(item => +item.id.split("-")[0]);
                setRegisteredCustomers([...cusIds])
            }
        })

        axiosInstance.get('/api/accessDevices/search/findByType?type=FACE').then(res => {
            if (res.status === 200 && res.data.content[0]?.id) {
                setDevices(res.data.content)
            }
        })
    }, [])

    const save = () => {
        const customerIds = utils.extractCustomerIdsV2(selected)
        axiosInstance.post('/api/face/', {customerIds, deviceNos: selectedDeviceNos}).then(res => {
            showSuccessNotify('Đăng ký thành công')
        }, err => {
            showErrorNotify(err.message)
        });
    }

    return <SimpleForm toolbar={<SimpleToolbar/>} save={save}>
        <BooleanInput label='Chưa đăng ký' onChange={(event) => {
            const checked = event.currentTarget.checked;
            if (checked) {
                const filtered = originalCustomers.filter(item => !item.customerId || !registeredCustomers.includes(item.customerId));
                setCustomers([...filtered])
            } else {
                setCustomers([...originalCustomers])
            }
        }}/>

        {devices?.length > 0 && <CheckboxGroupInput label='Thiết bị' source="deviceNos"
                             choices={devices.map(item => ({id: item.deviceNo, name: item.name}))}
                             onChange={(_, values) => setSelectedDeviceNos(values)}
        />}

        <label><b>Danh sách người tham dự</b></label>
        <div style={{width: 500, maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
            <TreeCheckBoxCustomInput data={customers} expandTopLevel={true} onChange={setSelected}/>
        </div>
    </SimpleForm>
}

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(FaceCreate)
