import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, required, SelectInput, TextInput} from 'react-admin';

const statusInFlow = {
    ABSENT_PREV_ON_TIME: [
        {id: 'ON_TIME', name: 'Đúng giờ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT_PREV_ON_TIME', name: 'Vắng chưa xin phép'}
    ],
    ABSENT_WITH_PERMISSION_PREV_ON_TIME: [
        {id: 'ON_TIME', name: 'Đúng giờ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION_PREV_ON_TIME', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    CM_PREV_ON_TIME: [
        {id: 'ON_TIME', name: 'Đúng giờ'},
        {id: 'CM_PREV_ON_TIME', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    ABSENT_PREV_IN_LATE: [
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT_PREV_IN_LATE', name: 'Vắng chưa xin phép'}
    ],
    ABSENT_WITH_PERMISSION_PREV_IN_LATE: [
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION_PREV_IN_LATE', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    CM_PREV_IN_LATE: [
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'CM_PREV_IN_LATE', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    ABSENT: [
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    ON_TIME: [
        {id: 'ON_TIME', name: 'Đúng giờ'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'},
        {id: 'CM', name: 'CM'}
    ],
    ABSENT_WITH_PERMISSION: [
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    MISSING_CARD: [
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    CM: [
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    IN_LATE: [
        {id: 'CM', name: 'CM'},
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    OFF_PREV_ON_TIME: [
        {id: 'ON_TIME', name: 'Đúng giờ'},
        {id: 'OFF_PREV_ON_TIME', name: 'OFF'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    OFF_PREV_IN_LATE: [
        {id: 'IN_LATE', name: 'Trễ'},
        {id: 'OFF_PREV_IN_LATE', name: 'OFF'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
    OFF: [
        {id: 'MISSING_CARD', name: 'Không thẻ'},
        {id: 'OFF', name: 'OFF'},
        {id: 'CM', name: 'CM'},
        {id: 'ABSENT_WITH_PERMISSION', name: 'Vắng có phép'},
        {id: 'ABSENT', name: 'Vắng chưa xin phép'}
    ],
}

const AuthorizedAttendanceHistoryFields = props => {
    const {record} = props;

    React.useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="unitId"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="customerName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="displaySummaryDate"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="unitName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="eventName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="displayTimeIn" {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectInput source="statusIn"
                             defaultValue={"ABSENT"}
                             validate={required()}
                             choices={statusInFlow[record.statusIn]} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="reason" {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <BooleanInput source="sendNotify" {...props} />
            </Grid>
        </React.Fragment>
    )
}

export default AuthorizedAttendanceHistoryFields
