import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { compose } from 'redux';
import {connect} from 'react-redux';

const styles = {
  formControl: {
    margin: 1,
    minWidth: 200
  }
};

function SingleSelectComponent(props) {
  const {classes, choices, selectedData, label, onChange} = props;
  const [displayData, setDisplayData] = React.useState(null);

  React.useEffect(() => {
    if (selectedData) {
      choices.forEach(c => {
        if (selectedData.includes(c.id)) {
          setDisplayData(c.value);
        }
      })
    }
  }, [choices, selectedData]);

  const handleChange = event => {
    const selectedVal = event.target.value;
    choices.forEach(c => {
      if (selectedVal === c.value) {
        onChange(c.id);
      }
    })
    setDisplayData(selectedVal);
  };


  return (
      <FormControl className={classes.formControl}>
        <InputLabel shrink>{label}</InputLabel>
        <Select
            value={displayData}
            onChange={handleChange}
        >
          {choices.map((choice) => (
              <MenuItem key={choice.id} value={choice.value} >
                {choice.value}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}

export default compose(
    connect(null),
    withStyles(styles)
)(SingleSelectComponent)
