import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const AccessDeviceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const KiotConfigList = props => (
    <List filters={<AccessDeviceFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="retailerId"/>
            <TextField source="branchId"/>
            <TextField source="saleChannelId"/>
            <BooleanField source="seller"/>
        </Datagrid>
    </List>
);

export default KiotConfigList
