import React, {Component} from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';

class BcChiTietThu extends Component {
    state = {
        loading: false,
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/bcChiTietThu?fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate;
    }

    excelReport = () => {
        this.setState({loading: true})
        downloadFile(this.getReportUrl(), 'bao_cao_chi_tiet_thu.xlsx').then(() => {
            this.setState({loading: false})
        }).catch(() => {
            this.setState({loading: false})
        })
    };

    validate = (values) => {
        const errors = {};
        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }
        // Copy input values to params
        this.setState(values);
        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="resources.bcChiTietThu.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.loading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default BcChiTietThu;

