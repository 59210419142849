import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';

import {Layout, Sidebar} from 'react-admin';
import AppBar from './CustomAppBar';
import Menu from './Menu';

const styles = {
    layout: {
        "& > div > main > div:nth-child(2)": {
            paddingTop: '12px',
            maxHeight: window.innerHeight * 0.9,
            height: window.innerHeight * 0.9,
            overflowY: 'auto'
        },
        '& > div > main > div:nth-child(2) > div:has(div>form)': {
            overflowY: 'auto'
        },
        '& > div > main > div:nth-child(2) > div > div:has(form)': {
            overflowY: 'auto'
        },
    }
}

const CustomSidebar = props => <Sidebar {...props} size={320}/>;
const CustomLayout = ({classes, schoolYear, ...props}) => (
    <Layout
        className={classes.layout}
        {...props}
        appBar={AppBar}
        sidebar={CustomSidebar}
        menu={Menu}
    />
);

const mapStateToProps = state => ({
    schoolYear: state.schoolYear,
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, {}),
)(CustomLayout);
