import React from 'react';
import {BooleanField, Datagrid, Filter, List, refreshView, TextField, TextInput, translate} from 'react-admin';
import './style.css';
import utils from "../../utils/utils";
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {EventTypeField} from "../../components/EventType";

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const EventList = props => {

  React.useEffect(() => {
    const searchCrits = decodeURIComponent(props.location.search);
    if (searchCrits) {
      let filterCrits = searchCrits.split('&')[0].replace('?', '');
      let searchQuery = utils.parseSearchQueryURL(filterCrits);
      if (searchQuery.refreshView === 'true') {
        setTimeout(() => {
          props.refreshView();
        }, 5000);// refresh after 5s
      }
    }
  }, []);

  return (
      <List filters={<EventFilter />} {...props} exporter={false} >
        <Datagrid rowClick="edit" >
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="startDate" />
          <TextField source="endDate" />
          <TextField source="startTime" />
          <TextField source="endTime" />
          <EventTypeField source="type" />
          <TextField source="quota" />
          <BooleanField source="enabled" />
          <AttendanceInfoButton label="Kết quả" />
          <RecentlyAttendanceInfoButton />
        </Datagrid>
      </List>
  )
};

export default compose(
    connect(undefined, { refreshView }),
    translate,
    withStyles(null)
)(EventList)


const AttendanceInfoButton = ({ record }) => (
    <a className='button' onClick='event.preventDefault();' target="_blank" rel="noopener noreferrer"
       href={`/#/eventInfo/` + record.id}
    >
      Biểu đồ
    </a>

);
AttendanceInfoButton.defaultProps = { addLabel: true };

const RecentlyAttendanceInfoButton = ({ record }) => (
    <a className='button' onClick='event.preventDefault();' target="_blank" rel="noopener noreferrer"
       href={`/#/onlineAttendanceInfo/` + record.id}
    >
      Trực quan
    </a>

);
RecentlyAttendanceInfoButton.defaultProps = { addLabel: true };
