import React, {Component} from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {addField} from 'ra-core';

import {DateInput, required, Title} from 'react-admin';
import {ReportForm} from '../../components';
import env from '../../env';
import CheckboxTree from "react-checkbox-tree";
import axiosInstance from "../../api/axios";
import {downloadFilePost} from "../../components/FileDownload";

const CheckboxTreeInput = addField(props => {
    const { input, label, meta, ...rest } = props;
    const onCheck = (values, node) => {
        if(node.checked){
            // onChange not update touched.
            input.onBlur(values)
        } else {
            input.onChange(values)
        }
    }

    const nodes = [{
        label: 'Chọn tất cả',
        value: -9999,
        children: rest.nodes
    }]

    return <>
        <label style={{color: (meta.touched && !!meta.error) ? 'red' : '' }}>{label}</label>
        <CheckboxTree {...rest} nodes={nodes} onCheck={onCheck} expanded={[-9999]} checked={input.value}/>
        <div style={{color: '#f44336', fontSize: '0.75rem'}}>{meta.touched && meta.error}</div>
    </>
})
class ChiTietThuPhiTheoNoiDungVaNguoiThu extends Component {
    state = {
        loading: false,
        products: [],
        cashers: [],
        paymentTypes: [],
        payload: {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            productNames: [],
            paymentTypes: [],
            cashers: []
        },
    };

    componentDidMount() {
        this.getInfoRecipientAndDetailReport()
    }

    getInfoRecipientAndDetailReport = () => {
        const url = `/reports/bcChiTietPhiVaNguoiThu/getInfo`
        axiosInstance.get(url).then(rs => {
            const {productNames, cashers, paymentTypes} = rs.data
            this.setState(
                {
                    products: productNames.map(item => ({value: item, label: item})),
                    cashers: cashers.map(item => ({value: item.username, label: item.fullName})),
                    paymentTypes: paymentTypes.map(item => ({value: `${item.id}`, label: item.gateway, method: item.method}))
                })
        });
    }

    getReportUrl = () => {
        return `${env.baseURL}/reports/bcChiTietPhiVaNguoiThu`
    }

    excelReport = async () => {
        const cashers = this.state.cashers
            .map(item => ({username: item.value, fullName: item.label}))
            .filter(item => this.state.payload.cashers.includes(item.username))

        const paymentTypes = this.state.paymentTypes
            .map(item => ({id: item.value, gateway: item.label, method: item.method}))
            .filter(item => this.state.payload.paymentTypes.includes(item.id))

        const data = {
            ...this.state.payload,
            cashers,
            paymentTypes,
        }

        this.setState({isLoading: true})
        await downloadFilePost(this.getReportUrl(), 'bao_cao_chi_tiet_thu_phi_theo_noi_dung_va_nguoi_thu.xlsx', data)
        this.setState({isLoading: false})
    };

    validate = (values) => {
        const errors = {};
        // Validate
        const {fromDate, toDate, productNames, paymentTypes, cashers} = values

        if (fromDate && toDate && (fromDate > toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        if(productNames.length === 0 ){
            errors.products = 'Bắt buộc'
        }

        if(paymentTypes.length === 0 ){
            errors.paymentTypes = 'Bắt buộc'
        }

        if(cashers.length === 0 ){
            errors.cashers = 'Bắt buộc'
        }

        this.setState({payload: {...values}})
        // Copy input values to params
        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="resources.bcChiTietPhiVaNguoiThu.name"/>
                <CardContent>
                    <ReportForm params={this.state.payload}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <CheckboxTreeInput
                                    label='Người thu *'
                                    source='cashers'
                                    nodes={this.state.cashers}
                                    showNodeIcon={false}
                                />

                                <div style={{margin: 32}}/>
                                <CheckboxTreeInput
                                    label='Phương thức thanh toán *'
                                    source='paymentTypes'
                                    nodes={this.state.paymentTypes}
                                    showNodeIcon={false}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <CheckboxTreeInput
                                    label='Danh sách phí *'
                                    source='productNames'
                                    nodes={this.state.products}
                                    showNodeIcon={false}
                                />
                            </Grid>

                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default ChiTietThuPhiTheoNoiDungVaNguoiThu;

