import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {DateTimeField} from "../../components";

const PollFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const PollList = props => (
  <List filters={<PollFilter />} {...props}  exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <DateTimeField source="startDate" format="DD-MM-YYYY" />
      <TextField source="startTime" />
      <DateTimeField source="endDate" format="DD-MM-YYYY" />
      <TextField source="endTime" />
      <VotingInfoButton />

    </Datagrid>
</List>
);

export default PollList

const VotingInfoButton = ({ record }) => (
  <a className='button' onClick='event.preventDefault();' target="_blank" rel="noopener noreferrer"
     href={`/#/votingInfo/` + record.id + `/2/2`}
  >
    Kết quả
  </a>

);
VotingInfoButton.defaultProps = { addLabel: true };
