import React from 'react';
import {printContent, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {CardActions, DateInput, required, SelectInput, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment';

const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
}
const types = [
    {id: 'ALL', name: 'Toàn bộ'},
    {id: 'HD', name: 'Chỉ hoá đơn'},
    {id: 'PT', name: 'Chỉ biên lai'},
    {id: 'BN', name: 'Chỉ biên nhận'}
]

class BatchPrintReceipt extends React.Component {

    state = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        type: 'ALL',
        isLoading: false
    };

    excelReport = () => {
        this.setState({isLoading: true})
        printContent(this.getReportUrl()).then(() => {
            this.setState({isLoading: false})
        }).catch(() => {
            this.setState({isLoading: false})
        })
    }

    getReportUrl = () => {
        return `${env.baseURL}/reports/print-both-batch-receipt?` +
            `type=` + this.state.type +
            `&fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate;
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        // Copy input values to params
        this.setState({
            type: values.type,
            fromDate: values.fromDate,
            toDate: values.toDate
        })

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.inNhieuHoaDon.name"/>
                <CardActions>
                    <iframe id="pdf-frame" style={iframeStyle}></iframe>
                </CardActions>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <SelectInput label={"resources.inNhieuHoaDon.type"} source="type" choices={types}
                                         validate={required()} {...this.props} style={{width: 200}}/>
                        </Grid>
                    </ReportForm>

                </CardContent>
            </Card>
        )
    }
}

export default BatchPrintReceipt;
