import React from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import axiosInstance from "../../api/axios";
import AttendanceResultValidation from "./AttendanceResultValidation";
import {Redirect} from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import {withStyles} from "@material-ui/core/styles";
import {Button, SaveButton, showNotification, SimpleForm, Title} from 'react-admin';
import AttendanceResultCustomToolbar from "./AttendanceResultCustomToolbar";
import AttendanceResultAuthorizationSummaryDetails from "./AttendanceResultAuthorizationSummaryDetails";
import {showErrorNotify} from "../../components";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';

const styles = theme => ({
    suggestDialog: {
        zIndex: '1 !important',
    },
});

const ATTENDANCE_STATUS_COLOR = {
    'CP': 'orange',
    'KP': 'red',
    'CM': '#64eb34',
    'OFF': 'gray'
}

const HAVE_TIME_CHANGE_STATUS_MAP = [
    'CP',
    'KP'
]

const ATTENDANCE_STATUS_CONVERTER = {
    'TR': 'IN_LATE',
    'DG': 'ON_TIME',
    'KP': 'ABSENT',
    'KT': 'MISSING_CARD',
    'CP': 'ABSENT_WITH_PERMISSION',
    'CM': 'CM',
    'OFF': 'OFF'
}
const NOT_CHANGE_ALL_ATTENDANCE_STATUS_MAP = [
    'ABSENT',
    'ABSENT_WITH_PERMISSION',
    'ON_TIME',
    'IN_LATE'
]

export class AttendanceResultAuthorizationEdit extends React.Component {

    state = {
        summaryDate: '',
        summaryName: '',
        unitName: '',
        editable: false,
        showDialog: false,
        details: [],
        rowsPerPage: 100,
        page: 0,
        showData: [],
        statusIns: [],
        totalMembers: '',
        totalMissing: '',
        totalLates: '',
        totalOffs: '',
        totalCms: '',
        totalOnTimes: '',
        totalMissingCards: '',
        totalAbsentWithoutPermissions: '',
        totalAbsentWithPermissions: '',
        lastChangedName: '',
        presents: '',
        lastChangedDate: '',
        popupRow: null,
        prevStatusIn: ''
    }

    componentDidMount() {
        this.getSummaryDetails(this.props.id);
    }

    getSummaryDetails = (id) => {
        const url = `/api/attendanceSummaryDetail/` + id;
        axiosInstance.get(url).then(rs => {
            const data = rs.data
            this.setState(
                {
                    summaryId: data.summaryId,
                    summaryDate: data.summaryDate,
                    summaryName: data.summaryName,
                    unitName: data.unitName,
                    editable: data.editable,
                    details: data.details,
                    statusIns: data.listStatusIn,
                    statusOuts: data.listStatusOut,
                    totalMembers: data.totalMembers,
                    totalMissing: data.totalMissing,
                    totalLates: data.totalLates,
                    totalOffs: data.totalOffs,
                    totalCms: data.totalCms,
                    totalMissingCards: data.totalMissingCards,
                    totalOnTimes: data.totalOnTimes,
                    totalAbsentWithoutPermissions: data.totalAbsentWithoutPermissions,
                    totalAbsentWithPermissions: data.totalAbsentWithPermissions,
                    lastChangedName: data.lastChangedName,
                    presents: data.presents,
                    lastChangedDate: data.lastChangedDate,
                    sendNotification: true
                })
            const startIdx = this.state.page * this.state.rowsPerPage;
            const endIdx = (this.state.page + 1) * this.state.rowsPerPage - 1;
            this.setShowData(startIdx, endIdx);
        });
    }

    handleChangePage = (event, newPage) => {
        const startIdx = newPage * this.state.rowsPerPage;
        const endIdx = (newPage + 1) * this.state.rowsPerPage - 1;
        this.setShowData(startIdx, endIdx);
        this.setState({page: newPage});
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value});
        const startIdx = this.state.page * event.target.value;
        const endIdx = (this.state.page + 1) * event.target.value - 1;
        this.setShowData(startIdx, endIdx);
    }

    setShowData = (startIdx, endIdx) => {
        const showData = [];
        let filteredData = this.state.details;
        if (endIdx > filteredData.length) {
            endIdx = filteredData.length - 1;
        }
        for (let i = startIdx; i <= endIdx; i++) {
            if (filteredData[i]) {
                showData.push(filteredData[i]);
            }
        }
        this.setState({showData: showData});
    }

    increaseNewStatusCount = (stt) => {
        const {
            totalAbsentWithoutPermissions,
            totalMissingCards,
            totalAbsentWithPermissions,
            totalOffs,
            totalCms,
            totalLates,
            totalOnTimes
        } = this.state;
        switch (stt) {
            case 'KP':
                this.setState({
                    totalAbsentWithoutPermissions: totalAbsentWithoutPermissions + 1
                });
                break;
            case 'KT':
                this.setState({
                    totalMissingCards: totalMissingCards + 1
                });
                break;
            case 'CP':
                this.setState({
                    totalAbsentWithPermissions: totalAbsentWithPermissions + 1
                });
                break;
            case 'OFF':
                this.setState({
                    totalOffs: totalOffs + 1
                });
                break;
            case 'CM':
                this.setState({
                    totalCms: totalCms + 1
                });
                break;
            case 'TR':
                this.setState({
                    totalLates: totalLates + 1
                });
                break;
            case 'DG':
                this.setState({
                    totalOnTimes: totalOnTimes + 1
                });
                break;
            default:
                break;
        }
    }

    decreaseOldStatusCount = (stt) => {
        const {
            totalAbsentWithoutPermissions,
            totalMissingCards,
            totalAbsentWithPermissions,
            totalOffs,
            totalCms,
            totalLates,
            totalOnTimes
        } = this.state;
        switch (stt) {
            case 'ABSENT':
                this.setState({
                    totalAbsentWithoutPermissions: totalAbsentWithoutPermissions - 1
                });
                break;
            case 'MISSING_CARD':
                this.setState({
                    totalMissingCards: totalMissingCards - 1
                });
                break;
            case 'ABSENT_WITH_PERMISSION':
                this.setState({
                    totalAbsentWithPermissions: totalAbsentWithPermissions - 1
                });
                break;
            case 'OFF':
                this.setState({
                    totalOffs: totalOffs - 1
                });
                break;
            case 'HD':
                this.setState({
                    totalCms: totalCms - 1
                });
                break;
            case 'IN_LATE':
                this.setState({
                    totalLates: totalLates - 1
                });
                break;
            case 'ON_TIME':
                this.setState({
                    totalOnTimes: totalOnTimes - 1
                });
                break;
            default:
                break;
        }
    }

    onChangeStatusIn = (row, stt, removePrevStatus = false) => {
        this.decreaseOldStatusCount(row.statusIn);
        this.increaseNewStatusCount(stt);
        const {prevStatusIn, prevTimeIn} = this.state
        const newArr = [...this.state.details]
        const index = newArr.findIndex(a => a.detailId === row.detailId);
        if (index >= 0) {
            newArr[index].statusIn = ATTENDANCE_STATUS_CONVERTER[stt];
            newArr[index].changed = true;
            newArr[index].editable = true;

            if (!newArr[index].prevStatusIn) {
                newArr[index].prevStatusIn = prevStatusIn;
                newArr[index].prevTimeIn = prevTimeIn;
            }
            if (removePrevStatus) {
                newArr[index].prevStatusIn = '';
                newArr[index].prevTimeIn = '';
            }
        }
        this.setState({details: newArr})

        const startIdx = this.state.page * this.state.rowsPerPage;
        const endIdx = (this.state.page + 1) * this.state.rowsPerPage - 1;
        this.setShowData(startIdx, endIdx);
    }

    cancel = () => {
        this.setState({
            isRedirect: true
        });
    }

    onChangedNotification = (val) => {
        this.setState({
            sendNotification: val
        });
    }

    preAuthorize = () => {
        const url = `/api/preAuthorizeAttendances`;
        const data = cloneDeep(this.state);
        data.details = this.state.details.filter(i => i.changed);
        return axiosInstance.put(url, {data: data}).then(rs => {
            if (rs.data.success) {
                this.cancel();
            }
        }, err => {
            this.props.showNotification(err.message, 'warning');
        });
    }

    authorize = () => {
        const url = `/api/authorizeAttendances`;
        const data = cloneDeep(this.state);
        return axiosInstance.post(url, {data: data}).then(rs => {
            if (rs.data.success) {
                this.cancel();
            } else {
                showErrorNotify(rs.error.message);
            }
        });
    }

    handleCheckAll = (prop, val) => {
        const newArr = [...this.state.details]
        let matchAll = true
        if (val) { // Select all
            for (let i = 0; i < newArr.length; i++) {
                const sttIn = newArr[i].statusIn
                if (NOT_CHANGE_ALL_ATTENDANCE_STATUS_MAP.indexOf(sttIn) >= 0 || (newArr[i].prevStatusIn && val === 'MISSING_CARD')) {
                    continue;
                }
                console.log(sttIn, prop, ATTENDANCE_STATUS_CONVERTER[prop])
                if (sttIn === ATTENDANCE_STATUS_CONVERTER[prop]) {
                    if (newArr[i].changed) {
                        newArr[i].statusIn = ATTENDANCE_STATUS_CONVERTER[prop];
                    }
                    if (matchAll) {
                        matchAll = newArr[i].statusIn === ATTENDANCE_STATUS_CONVERTER[prop]
                    }
                } else {
                    newArr[i].statusIn = ATTENDANCE_STATUS_CONVERTER[prop];
                    newArr[i].changed = true;
                }
            }
            this.setState({
                details: newArr,
                // totalOffs: newArr.filter(a => a.statusIn === 'OFF').length,
                totalCms: newArr.filter(a => a.statusIn === 'HD').length,
                totalMissingCards: newArr.filter(a => a.statusIn === 'MISSING_CARD').length,
                totalAbsentWithoutPermissions: newArr.filter(a => a.statusIn === 'ABSENT').length,
                totalAbsentWithPermissions: newArr.filter(a => a.statusIn === 'ABSENT_WITH_PERMISSION').length,
                totalLates: newArr.filter(a => a.statusIn === 'IN_LATE').length,
                totalOnTimes: newArr.filter(a => a.statusIn === 'ON_TIME').length
            })
        }
    }

    closeChangeStatusPopup = () => {
        this.setState({
            showDialog: false,
            showChangePrevStatusPopup: false,
            prevStatusIn: '',
            prevTimeIn: '',
            popupRow: null
        })
    }

    saveChangeStatusPopup = () => {
        const {statusPopup, popupRow} = this.state
        if (statusPopup) {
            this.onChangeStatusIn(popupRow, statusPopup)
            this.setState({
                statusPopup: '',
                showDialog: false,
                prevStatusIn: '',
                prevTimeIn: '',
            })
        }
    }
    saveChangePrevStatusPopup = () => {
        const {popupRow, statusPopup} = this.state
        if (statusPopup) {
            this.onChangeStatusIn(popupRow, statusPopup, true)
            this.setState({
                statusPopup: '',
                showChangePrevStatusPopup: false,
                prevStatusIn: '',
                prevTimeIn: '',
            })
        }
    }

    doChangePopupStatus = (statusPopup) => {
        this.setState({
            statusPopup: statusPopup,
        })
    }

    openPopupChangeStatusHaveTime = (row, stt, index) => {
        this.setState({
            showDialog: true,
            prevStatusIn: row.statusIn,
            prevTimeIn: row.timeIn,
            statusPopup: '',
            popupRow: row,
            index: index
        })
    }
    openPopupChangePrevStatus = (row, index) => {
        this.setState({
            showChangePrevStatusPopup: true,
            prevStatusIn: row.prevStatusIn === 'IN_LATE' ? 'Trễ' : 'Đúng giờ',
            prevTimeIn: row.prevTimeIn,
            statusPopup: row.prevStatusIn === 'IN_LATE' ? 'TR' : 'DG',
            popupRow: row,
            index: index
        })
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/authorizeAttendances'/>;
        }
        return (
            <div>
                <Title title={this.state.unitName}/>
                <Dialog
                    className={this.props.classes.suggestDialog}
                    fullWidth={true}
                    maxWidth='sm'
                    open={this.state.showDialog}
                    onClose={this.closeChangeStatusPopup}
                    aria-label="Chuyển trạng thái"
                >
                    <DialogTitle>Chuyển trạng thái</DialogTitle>
                    <DialogContent>
                        {HAVE_TIME_CHANGE_STATUS_MAP.map((stt, idx) => (
                            <Button variant="outlined"
                                    onClick={e => this.doChangePopupStatus(stt)}
                                    className={this.props.classes.button}
                                    style={{
                                        marginRight: 10,
                                        backgroundColor: this.state.statusPopup === stt ? ATTENDANCE_STATUS_COLOR[stt] : '#fff'
                                    }}>
                  <span
                      style={{color: this.state.statusPopup === stt ? '#fff' : 'black'}}>{stt}</span>
                            </Button>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            label={'Xác nhận'}
                            saving={this.state.isSubmitting}
                            onClick={this.saveChangeStatusPopup}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.closeChangeStatusPopup}
                        >
                            <IconCancel/>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className={this.props.classes.suggestDialog}
                    fullWidth={true}
                    maxWidth='sm'
                    open={this.state.showChangePrevStatusPopup}
                    onClose={this.closeChangeStatusPopup}
                    aria-label="Chuyển trạng thái"
                >
                    <DialogTitle>{'Chuyển trạng thái về ' + this.state.prevStatusIn + '. Giờ chạm thẻ ' + (this.state.prevTimeIn || '').split(' ').reverse()[0]}</DialogTitle>

                    <DialogActions>
                        <SaveButton
                            label={'Xác nhận'}
                            saving={this.state.isSubmitting}
                            onClick={this.saveChangePrevStatusPopup}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.closeChangeStatusPopup}
                        >
                            <IconCancel/>
                        </Button>
                    </DialogActions>
                </Dialog>
                <SimpleForm
                    toolbar={<AttendanceResultCustomToolbar
                        redirect={"list"}
                        authorize={this.authorize}
                        cancel={this.cancel}
                        preAuthorize={this.preAuthorize}
                        editable={this.state.editable}/>}
                >
                    <AttendanceResultAuthorizationSummaryDetails summaryName={this.state.summaryName}
                                                                 summaryDate={this.state.summaryDate}
                                                                 totalLates={this.state.totalLates}
                                                                 totalMembers={this.state.totalMembers}
                                                                 totalAbsentWithoutPermissions={this.state.totalAbsentWithoutPermissions}
                                                                 totalAbsentWithPermissions={this.state.totalAbsentWithPermissions}
                                                                 totalMissingCards={this.state.totalMissingCards}
                                                                 totalOnTimes={this.state.totalOnTimes}
                                                                 totalCms={this.state.totalCms}
                                                                 lastChangedName={this.state.lastChangedName}
                                                                 lastChangedDate={this.state.lastChangedDate}
                                                                 enabled={this.state.editable}
                                                                 onChangedNotification={this.onChangedNotification}
                                                                 handleCheckAll={this.handleCheckAll}/>
                    {
                        this.state.details && <AttendanceResultValidation
                            data={this.state.showData}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            handleChangePage={this.handleChangePage}
                            total={this.state.details.length}
                            onChangeStatusIn={this.onChangeStatusIn}
                            openPopupChangePrevStatus={this.openPopupChangePrevStatus}
                            openPopupChangeStatusHaveTime={this.openPopupChangeStatusHaveTime}
                            statusIns={this.state.statusIns}
                            enabled={this.state.editable}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                    }
                </SimpleForm>
            </div>
        )
    }
}

export default compose(
    connect(undefined, {showNotification}),
    withStyles(styles),
)(AttendanceResultAuthorizationEdit);

