import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, NumberInput, required, SelectInput, TextInput} from 'react-admin';

const BillProductFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="price" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="maskName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="printType" validate={required()} choices={[
                {
                    id: 'HDDT',
                    name: 'Hoá đơn điện tử'
                },
                {
                    id: 'BIEN_LAI',
                    name: 'Biên lai'
                },
                {
                    id: 'BIEN_NHAN',
                    name: 'Biên nhận'
                }]}
                         {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enable" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="flexible" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="priority" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="subscriptionType" validate={required()} choices={[
                {
                    id: 'PER_YEAR',
                    name: 'Theo năm'
                },
                {
                    id: 'PER_PERIOD',
                    name: 'Theo kỳ'
                },
                {
                    id: 'PER_MONTH',
                    name: 'Theo tháng'
                }, {
                    id: 'PER_DAY',
                    name: 'Theo ngày'
                }]}
                         {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput defaultValue={1} source="limitCreated" validate={required()} {...props} />
        </Grid>
    </React.Fragment>
)

export default BillProductFields
