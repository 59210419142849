import React from 'react';
import {Create, SimpleForm, showNotification} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Grid} from '../../components'
import moment from 'moment';
import axiosInstance from "../../api/axios";
import EventFields from "./EventFields";
import utils from "../../utils/utils";

let timeout = null;
export const EventCreate = ({redirect="list", event, ...props}) => {
  const [values, setValues] = React.useState({
    name: '',
    infoLink: '',
    enabled: true,
    recurring: true,
    accessControlOnly: false,
    authorizeAttendance: true,
    type: 'ATTENDANCE',
    recurringType: 'WEEKLY',
    daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    separationCount: 1,
    eventGroupId: null,
    eventLocationId: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: null,
    startTime: '08:00',
    endTime: '11:30',
    needCheckIn: true,
    needCheckOut: false,
    lateComePermit: 5,
    earlyLeavePermit: 0,
    openForCheckIn: 0,
    endForCheckIn: 0,
    openForCheckOut: 0,
    endForCheckOut: 0,
    quota: 0,
    deviceIds: [],
    deviceOutIds: [],
    sourceEventIds: [],
    customerIds: [],
    unitIds: [],
    excludeUnitIds: [],
    cuzIds: [],
    tmpUnitIds: [],
    tmpExcludeUnitIds: [],
    isRedirect: false,
    saveDisabled: true
  });

  React.useEffect(() => {
    validate(false);
  }, [values.name, values.startDate, values.endDate]);

  const handleChange = prop => e => {
    if (!e) {
      return
    }
    let value;
    if (['recurringType', 'tmpExcludeUnitIds', 'tmpUnitIds', 'cuzIds'].includes(prop)) {
      value = e;
    } else {
      value = e.target.value ? e.target.value : e.target.checked;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setValues({...values, [prop]: value});
    }, 500);
  };

  function onDirectChange(prop, val) {
    setValues({...values, [prop]: val});
  }

  function save() {
    if (!validate( true)) {
      return;
    }
    values.customerIds = utils.extractCustomerIds(values.cuzIds);
    values.unitIds = values.tmpUnitIds;
    values.excludeUnitIds = values.tmpExcludeUnitIds;
    const url = `/api/events`;
    axiosInstance.post(url, values).then(rs => {
      props.showNotification('Tạo event thành công');
      setValues({...values, isRedirect: true});
    }, err => {
      props.showNotification(err.message, 'warning');
    })
  }

  function validate(isShowNotification) {
    if (isShowNotification) {//show details error
      if (values.startDate > values.endDate) {
        props.showNotification('Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
            'warning');
        return false;
      }
      if (!values.name) {
        props.showNotification('Bắt buộc nhập tên event', 'warning');
        return false;
      }
      if (!values.startDate) {
        props.showNotification('Bắt buộc nhập ngày bắt đầu', 'warning');
        return false;
      }
      if (!values.endDate) {
        props.showNotification('Bắt buộc nhập ngày kết thúc', 'warning');
        return false;
      }
    } else {
      if (!values.name || !values.startDate || !values.endDate) {
        setValues({...values, saveDisabled: true});
        return false;
      }
    }
    setValues({...values, saveDisabled: false});
    return true;
  }

  return (
      <Create {...props} location={{ state: { record: values } }}>
        <SimpleForm toolbar={null}>
          <Grid container spacing={8}>
            <EventFields onChange={handleChange}
                          defaultValue={values}
                          save={save}
                          onDirectChange={onDirectChange}/>
          </Grid>
        </SimpleForm>
      </Create>
  )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(EventCreate)
