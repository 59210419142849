import React from 'react';
import { compose } from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {showNotification} from 'react-admin';
import axiosInstance from "../api/axios";
import ImageUploadComponent from "./ImageUploadComponent";

const styles = {
}

const ImageUploadWrapper = (props) => {

  const [file, setFile] = React.useState(null);

  const onFileUpload = (e) => {
    e.preventDefault(); // Stop form submit
    if (file == null) {
      return;
    }
    fileUpload(file).then((response) => {
      props.showNotification('Cập nhật hình thành công');
    })
  };

  const fileUpload = (file) => {
    const {id} = props.record;
    const url = props.url + id;
    const formData = new FormData();
    formData.append('file', file)
    return axiosInstance.post(url, formData);
  }

  return (
      <React.Fragment>
        <ImageUploadComponent onFileChanged={(e) => setFile(e.target.files[0])}
                              onFileUpload={onFileUpload}/>
      </React.Fragment>
  );
}

export default compose(
    connect(null, { showNotification }),
    withStyles(styles)
)(ImageUploadWrapper)
